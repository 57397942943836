@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html {
  scroll-behavior: smooth !important;
}

input {
  outline: none;
  outline-width: 0;
}

.bg-grid {
  margin: 0;
  margin-left: -2px;
  padding: 0;

  background-image: linear-gradient(rgb(31 41 55) 0.1em, transparent 0.1em),
    linear-gradient(90deg, rgb(31 41 55) 0.1em, transparent 0.1em);

  background-size: 20px 20px;

  @media (min-width: 1024px) {
    background-size: 35px 35px;
  }

  @media (max-width: 1024px) {
    background-size: 20px 20px;
  }

  @media (max-width: 1024px) {
    background-size: 20px 20px;
  }

  @media (max-width: 768px) {
    background-size: 20px 20px;
  }

  @media (max-width: 640px) {
    background-size: 20px 20px;
  }
}

.bg-grid-sm {
  margin: 0;
  margin-left: -2px;
  padding: 0;

  background-image: linear-gradient(rgb(31 41 55) 0.1em, transparent 0.1em),
    linear-gradient(90deg, rgb(31 41 55) 0.1em, transparent 0.1em);

  background-size: 5px 5px;

  @media (min-width: 1024px) {
    background-size: 5px 5px;
  }

  @media (max-width: 1024px) {
    background-size: 5px 5px;
  }

  @media (max-width: 1024px) {
    background-size: 5px 5px;
  }

  @media (max-width: 768px) {
    background-size: 5px 5px;
  }

  @media (max-width: 640px) {
    background-size: 5px 5px;
  }
}

/* Fix styling bug in react-select components caused by @tailwindcss/forms.  */
.react-select input[type='text']:focus {
  box-shadow: none;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 0.8s infinite;
}
